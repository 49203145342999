import React from 'react';

import Layout from '../components/layout';
import SEO from "../components/seo";

import './page.scss';

export default ({pageContext}) => (
    <Layout>
        <SEO 
            title={pageContext.yoast.title}
            description={pageContext.yoast.metadesc}
            ogimg={pageContext.yoast.opengraph_image.source_url}
            link={pageContext.link}
            />        
        <div className="metrix default-template">
            <div className="content-block" dangerouslySetInnerHTML={{__html: pageContext.content}}></div>
        </div>
    </Layout>
)